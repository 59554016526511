import BareButton from 'primitives/Buttons/BareButton';
import ListenInApp from 'components/ListenInApp';
import logger from 'modules/Logger';
import News from 'views/Podcast/PodcastProfile/LatestNews';
import PageBody, { ViewName } from 'views/PageBody';
import PodcastEpisodeCard from 'views/Podcast/PodcastEpisodeCard';
import PodcastEpisodeCards from 'views/Podcast/PodcastEpisodeCards';
import PodcastEpisodeHead from 'views/Podcast/PodcastProfile/PodcastEpisodeHead';
import PodcastEpisodesHead from 'views/Podcast/PodcastProfile/PodcastEpisodesHead';
import PodcastHero from 'views/Podcast/PodcastHero';
import PodcastSideRail from 'views/Podcast/PodcastSideRail';
import PodcastTranscriptionContainer from './PodcastTranscription/PodcastTranscriptionContainer';
import PopularPodcasts from 'views/Podcast/PodcastProfile/PopularPodcasts';
import qs from 'qs';
import ReorderIconDown from 'styles/icons/ReorderDown';
import ReorderIconUp from 'styles/icons/ReorderUp';
import Section from 'primitives/Section';
import ShouldShow from 'components/ShouldShow';
import transport from 'api/transport';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ASPECT_RATIOS } from 'utils/mediaServerImageMaker/opsString/constants';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import { getPodcastUrl } from 'state/Podcast/helpers';
import { Heading, SectionHeading } from 'components/SectionHeading';
import { LatestNews, NewsChild } from 'components/LatestNews';
import { Route } from 'react-router-dom';
import { setPodcastLastViewed } from 'state/Podcast/services';
import { useEffect, useRef, useState } from 'react';
import type { AxiosPromise } from 'axios';
import type { Episode, Host, PodcastArticle } from 'state/Podcast/types';
import type { User } from 'state/User/types';

export type Props = {
  ampUrl: string;
  articles: Array<PodcastArticle>;
  contentLink: string;
  description: string;
  episode: Episode | null;
  hosts: Array<Host>;
  imgUrl: string;
  pathname: string | null;
  profileId: number | null;
  seedId: number;
  seedType: string;
  sessionId: string | null;
  showType: string | undefined;
  slug: string;
  title: string;
  user: User;
  newPodcastProfile: boolean;
};

const PODCAST_PROFILE_LIMIT = 5;

const deriveSortAsc = (showType: Props['showType']) => showType === 'serial';

function PodcastProfile({
  ampUrl,
  articles,
  contentLink,
  description,
  episode,
  hosts,
  imgUrl,
  pathname,
  profileId,
  seedId,
  seedType,
  sessionId,
  showType,
  slug,
  title,
}: Props) {
  const translate = useTranslate();
  const [position, setPosition] = useState<number>();

  const [sortAsc, setsortAsc] = useState<boolean>(deriveSortAsc(showType));

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setsortAsc(deriveSortAsc(showType));
  }, [showType]);

  useMount(() => {
    function updateLastViewed(): AxiosPromise<void | Error> {
      if (ampUrl && profileId && sessionId && seedId) {
        return transport(
          setPodcastLastViewed({
            ampUrl,
            profileId,
            sessionId,
            podcastId: seedId,
          }),
        );
      } else {
        return Promise.reject(
          new Error('required information missing for transport'),
        );
      }
    }
    updateLastViewed().catch(e => {
      const err = e instanceof Error ? e : new Error(e);
      logger.error(err.message, Object.values(err), {}, err);
    });
  });

  useMount(() => {
    if (__CLIENT__) {
      const queryString = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      setPosition(Number(queryString.position));
    }
  });

  const headingButton = (
    <BareButton
      data-test="reorder-icon-button"
      onClick={() => setsortAsc(!sortAsc)}
    >
      <ShouldShow hiddenElement={<ReorderIconUp />} shouldShow={sortAsc}>
        <ReorderIconDown />
      </ShouldShow>
    </BareButton>
  );

  const markup = {
    hosts,
    description,
    image: imgUrl ?? undefined,
    name: title,
    url: pathname ?? undefined,
  };

  return (
    <>
      <ListenInApp seedId={seedId} seedType={seedType} />
      <PodcastEpisodesHead />

      {/* NESTED ROUTES SO THAT WE ONLY RERENDER EPISODES COMPONENT */}
      <Route
        exact
        path="/podcast/:slugifiedId"
        render={() => (
          <>
            <PodcastHero />
            <PageBody
              dataTest={ViewName.PodcastProfile}
              hasHero
              more={<PodcastSideRail isAbTest />}
              richResultsMarkup={markup}
            >
              {/* IHRWEB-17219 - Moved title out of pagebody to avoid multiple H1 elements. Use Show title in Hero as H1 */}
              <Section
                data-test={`${ViewName.PodcastProfile}-section-top`}
                type="top"
              >
                <SectionHeading
                  data-test={`${ViewName.PodcastProfile}-section-heading`}
                >
                  <Heading as="h2">{translate('Episodes')}</Heading>
                  {headingButton}
                </SectionHeading>
              </Section>
              <PodcastEpisodeCards sortAsc={sortAsc} />
              <News />
              <PopularPodcasts
                itemSelectedLocation="podcast_profile|popular_podcasts|tile"
                tilesInRow={PODCAST_PROFILE_LIMIT}
              />
            </PageBody>
          </>
        )}
      />

      {/* NESTED ROUTES SO THAT WE ONLY RERENDER EPISODE COMPONENT */}
      <Route
        exact
        path="/podcast/:slugifiedId/episode/:episodeSlug"
        render={() => {
          if (episode === null) return null;
          const { episodeTranscription, transcriptionAvailable } =
            episode as Episode;

          return (
            <>
              <PodcastEpisodeHead />
              <PageBody
                backLink={`/podcast/${slug}-${seedId}`}
                dataTest={ViewName.PodcastEpisode}
                more={<PodcastSideRail />}
                title={translate('All Episodes')}
              >
                <PodcastEpisodeCard
                  currentPodcast={{
                    title,
                    description,
                    hosts,
                    imgUrl,
                    pathname,
                  }}
                  onClick={() =>
                    ref?.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                  position={position}
                />
                <PodcastTranscriptionContainer
                  episodeTranscription={episodeTranscription}
                  ref={ref}
                  transcriptionAvailable={transcriptionAvailable}
                />
                <LatestNews
                  heading={`${title} News`}
                  sectionUrl={`${getPodcastUrl(seedId, slug)}news/`}
                >
                  {articles.slice(0, 3).map(article => (
                    <NewsChild
                      href={`${contentLink}${article.slug}/`}
                      img={article.summary.image}
                      isSponsored={article.payload.is_sponsored}
                      key={article.slug.replace(' ', '').toLowerCase()}
                      ops={[fit(...ASPECT_RATIOS['16:9'])]}
                      title={article.summary.title}
                    />
                  ))}
                </LatestNews>
              </PageBody>
            </>
          );
        }}
      />
    </>
  );
}

export default PodcastProfile;
