import loadable from '@loadable/component';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import withAnalytics from 'modules/Analytics/withAnalytics';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getPodcastEpisodesWithAuth } from 'state/Podcast/actions';
import { getPodcastTranscriptionsEnabled } from 'state/Features/selectors';
import { getSeedId, getTitle } from 'state/Podcast/selectors';
import { getStationLoaded } from 'state/Player/selectors';
import { Props } from './PodcastEpisodeCards';
import { State } from 'state/types';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';

const PodcastEpisodeCards = loadable(() => import('./PodcastEpisodeCards'));

export default flowRight(
  connect(
    createStructuredSelector<
      State,
      {
        seedId: number;
        showPodcastTranscriptions?: boolean;
        stationLoaded?: boolean;
        title: string;
        widgetDimensions?: WidgetDimensions;
      }
    >({
      seedId: getSeedId,
      showPodcastTranscriptions: getPodcastTranscriptionsEnabled,
      stationLoaded: getStationLoaded,
      title: getTitle,
      widgetDimensions: state =>
        state.features.flags.widgetPodcastEpisode ||
        state.features.flags.widgetPodcastProfile
          ? WIDGET_DIMENSIONS.PODCAST_EPISODE
          : undefined,
    }),
    { getPodcastEpisodesWithAuth },
  ),
  withAnalytics(
    ({ seedId, title }: Props) => ({
      id: `podcast|${seedId}`,
      name: title,
      pageName: 'podcast_profile',
    }),
    {
      trackOnDidUpdate: (prevProps: Props, nextProps: Props) =>
        !!nextProps.seedId &&
        String(prevProps.seedId) !== String(nextProps.seedId),
      trackOnWillMount: nextProps => !!nextProps.seedId,
    },
  ),
)(PlayerStateProxy(PodcastEpisodeCards));
