import loadable from '@loadable/component';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import withAnalytics from 'modules/Analytics/withAnalytics';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getCurrentPodcastEpisode,
  getEpisodeId,
  getEpisodeTitle,
  getSeedId,
  getSlug,
  getTitle,
} from 'state/Podcast/selectors';
import { getPodcastEpisodeWithAuth } from 'state/Podcast/actions';
import { getStationLoaded } from 'state/Player/selectors';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';
// State
import { Episode } from 'state/Podcast/types';
import { getPodcastTranscriptionsEnabled } from 'state/Features/selectors';
import { Props } from './PodcastEpisodeCard';
import { State } from 'state/types';

const PodcastEpisodeCard = loadable(() => import('./PodcastEpisodeCard'));

export default flowRight(
  connect(
    createStructuredSelector<
      State,
      {
        episode: Episode | null;
        episodeId: number | null;
        episodeTitle: string;
        seedId: number;
        slug: string;
        stationLoaded?: boolean;
        showPodcastTranscriptions?: boolean;
        title: string;
        widgetDimensions?: WidgetDimensions;
      }
    >({
      episode: getCurrentPodcastEpisode,
      episodeId: getEpisodeId,
      episodeTitle: getEpisodeTitle,
      seedId: getSeedId,
      showPodcastTranscriptions: getPodcastTranscriptionsEnabled,
      slug: getSlug,
      stationLoaded: getStationLoaded,
      title: getTitle,
      widgetDimensions: state =>
        state.features.flags.widgetPodcastEpisode ||
        state.features.flags.widgetPodcastProfile
          ? WIDGET_DIMENSIONS.PODCAST_EPISODE
          : undefined,
    }),
    { getPodcastEpisodeWithAuth },
  ),
  withAnalytics(
    ({ episodeId, episodeTitle, seedId, title }: Props) => ({
      id: `podcast|${seedId}`,
      name: title,
      pageName: 'podcast_episode_profile',
      subId: `episode|${episodeId}`,
      subName: episodeTitle,
    }),
    {
      trackOnDidUpdate: (prevProps: Props, nextProps: Props) =>
        prevProps.episodeId !== nextProps.episodeId,
    },
  ),
)(PlayerStateProxy(PodcastEpisodeCard));
