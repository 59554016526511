import Eyebrow from 'views/News/ContentArticle/primitives/Eyebrow';
import ImageWrapper from './primitives/ImageWrapper';
import ItemTitle from './primitives/ItemTitle';
import ListItem from './primitives/ListItem';
import MediaServerImage from 'components/MediaServerImage';
import NavLink from 'components/NavLink';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { TileData } from 'components/ShowMoreTiles/types';

type Props = {
  href: string;
  img: string;
  isSponsored?: boolean;
  ops?: Array<(...args: Array<any>) => any>;
  title: string;
};

type CreateTileProps = Props & {
  key: string;
  tilesInRow: number;
  translate: IGetTranslateFunctionResponse;
};

export const NEWS_ASPECT_RATIO = 1.77;

export const createNewsTile = ({
  title,
  isSponsored = false,
  href,
  img,
  key,
  ops = [],
  tilesInRow,
  translate,
}: CreateTileProps): TileData => ({
  children: (
    <NavLink target="_blank" to={href}>
      <MediaServerImage
        alt={title}
        aspectRatio={NEWS_ASPECT_RATIO}
        ops={ops}
        src={img}
      />
    </NavLink>
  ),
  dataTest: 'news-item-list-item',
  key,
  title: (
    <ItemTitle data-test="news-item-item-title" showMoreTile>
      {isSponsored ? <Eyebrow>{translate('Sponsor Content')}</Eyebrow> : null}
      <NavLink target="_blank" to={href}>
        <div>
          <Truncate lines={2}>{title}</Truncate>
        </div>
      </NavLink>
    </ItemTitle>
  ),
  tilesInRow,
});

function NewsItem({ title, isSponsored = false, href, img, ops = [] }: Props) {
  const translate = useTranslate();

  return (
    <ListItem data-test="news-item-list-item">
      <ImageWrapper data-test="news-item-image-wrapper">
        <NavLink target="_blank" to={href}>
          <MediaServerImage
            alt={title}
            aspectRatio={NEWS_ASPECT_RATIO}
            background
            ops={ops}
            src={img}
          />
        </NavLink>
      </ImageWrapper>
      <ItemTitle data-test="news-item-item-title">
        {isSponsored ? <Eyebrow>{translate('Sponsor Content')}</Eyebrow> : null}
        <NavLink target="_blank" to={href}>
          <div>
            <Truncate lines={2}>{title}</Truncate>
          </div>
        </NavLink>
      </ItemTitle>
    </ListItem>
  );
}

export default NewsItem;
